@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
@import "bootstrap";

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('fonts/IBMPlexMono.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('fonts/IBMPlexSans-Bold.woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('fonts/IBMPlexSans.woff');
    font-weight: normal;
    font-style: normal;
}


body {
}
	.light {
		background-color: #fff;
		.site-footer {	background-color: #e5e5e5;}

	}
	.dark {
		background-color: #222;

		h1,h2, h3, h4, p, a, a:hover {color: #fff}
		.navbar-default .navbar-nav > li > a {color: #fff;}
	}


.page {
	margin-top: 60px;
}

.square {
	margin-bottom: 30px;
	img {width: 100%;}
}
.spacer {margin-top: 100px;}

.btn.btn-default {
	background:  none;
	border-radius:0;
	border-width: 2px;
	border-color: #D50063;
	color: #D50063;
	&:hover {text-decoration:underline;}
}

.navbar {
	margin: 0;
	border-radius:0;
	border:none;
	background:none;
	//height: 100px;
	
	.navbar-brand img {
		height: 25px;
	}
}


// Footer

.site-footer {
	color: #fff;
	padding: 10px 0;


	p {margin: 0;}
	a {color:#fff;}
	a:hover {
		color: #fff;
		text-decoration: underline;
	}
}
.modal-body {
	img {width: 100%;}
	padding: 0;
}

/*no collapse*/
.off .navbar-header {
float:left;
}
.navbar-collapse.collapse.off {
    display: block!important;
}
.navbar-collapse.collapse.off ul {
    margin: 0;
    padding: 0;
}

.navbar-nav.no-collapse>li,
.navbar-nav.no-collapse {
    float: left !important;
}

.navbar-right.no-collapse {
    float: right!important;
}

